import {
  crsArticles,
  crsOpeningTexts,
  crsPrimaryMarkets,
  crsRankings,
  crsSecondaryMarkets,
  crsStock,
  crsYearbookDescriptions,
} from './CRs';
import {
  fiagroOpeningTexts,
  fiagroPrimaryMarkets,
  fiagroRankings,
  fiagroSecondaryMarkets,
  fiagroStock,
  fiagroYearbookDescriptions,
} from './FIAGRO';
import {
  fidcArticles,
  fidcOpeningTexts,
  fidcPrimaryMarkets,
  fidcRankings,
  fidcSecondaryMarkets,
  fidcStockMarkets,
  fidcYearbookDescriptions,
} from './FIDC';
import {
  fiiOpeningTexts,
  fiiPrimaryMarkets,
  fiiRankings,
  fiiSecondaryMarkets,
  fiiStockMarkets,
  fiiYearbookDescriptions,
} from './FII';
import {
  AdvertisementBanner,
  LetterToReader,
  Sponsor,
  SponsorLevel,
  YearbookDescriptions,
  YearbookMarket,
  YearbookMarketArticle,
  YearbookMarketChartInfo,
  YearbookMarketRanking,
  YearbookMarketVideo,
} from '../types';

interface AdvertisementList {
  [key: string]: AdvertisementBanner[];
}

interface MarketDataList {
  [key: string]: YearbookMarketChartInfo[];
}

interface MarketRankingList {
  [key: string]: YearbookMarketRanking[];
}

interface MarketArticlesList {
  [key: string]: YearbookMarketArticle[];
}

interface MarketDescriptionsList {
  [key: string]: YearbookDescriptions;
}

interface OpeningsData {
  [key: string]: string[];
}

export const paywallData = {
  title: 'Acesso não autorizado',
  subtitle:
    'Você precisa realizar login na plataforma para visualizar o anuário, você pode efetuar login ou se cadastrar utilizando o botão abaixo.',
  buttonLabel: 'Entrar',
  url: '/login?redirectUrl=/anuarios/2025/CRs/Inicio',
};

export const noPermissionPaywallData = {
  title: 'Acesso não autorizado',
  subtitle:
    'A sua assinatura não tem permissão para acessar o anuário, você pode atualizar sua assinatura ou contratar uma nova assinatura utilizando o botão abaixo.',
  buttonLabel: 'Atualizar assinatura',
  url: '/login?redirectUrl=/planos',
};

export const markets: YearbookMarket[] = [
  {
    name: 'CRs',
  },
  {
    name: 'FII',
  },
  {
    name: 'FIDC',
  },
  {
    name: 'FIAGRO',
  },
];

export const advertisements: AdvertisementList = {
  CRs: [],
  FIDC: [
    {
      url: 'https://www.mastercctvm.com.br',
      banner: 'master',
    },
    {
      url: 'https://www.nuclea.com.br/',
      banner: 'nuclea',
    },
  ],
  FII: [],
  FIAGRO: [],
};

export const descriptions: MarketDescriptionsList = {
  FIAGRO: fiagroYearbookDescriptions,
  CRs: crsYearbookDescriptions,
  FII: fiiYearbookDescriptions,
  FIDC: fidcYearbookDescriptions,
};

export const openings: OpeningsData = {
  CRs: crsOpeningTexts,
  FII: fiiOpeningTexts,
  FIDC: fidcOpeningTexts,
  FIAGRO: fiagroOpeningTexts,
};

export const sponsors: Sponsor[] = [
  {
    level: SponsorLevel.PLATINUM,
    site: 'https://www.pmkadvogados.com.br/',
    markets: ['CRs'],
    image: 'pmk',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.flha.com.br/',
    markets: ['CRs'],
    image: 'franco-leutewiler-henriques',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.idtrust.com.br/',
    markets: ['FIDC'],
    image: 'idtrust',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.terconbr.com.br',
    markets: ['FIDC'],
    image: 'tercon',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'www.solisinvestimentos.com.br',
    markets: ['FIDC'],
    image: 'solis',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.austin.com.br/',
    markets: ['FIDC'],
    image: 'austin',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.oliveiratrust.com.br',
    markets: ['FIDC'],
    image: 'oliveira-trust',
  },
  {
    level: SponsorLevel.SILVER,
    site: 'https://www.mastercctvm.com.br/',
    markets: ['FIDC'],
    image: 'master',
  },
  {
    level: SponsorLevel.SILVER,
    site: 'https://www.nuclea.com.br/',
    markets: ['FIDC'],
    image: 'nuclea',
  },
  {
    level: SponsorLevel.GOLD,
    site: 'https://www.oslodtvm.com/',
    markets: ['FIDC'],
    image: 'oslo',
  },
  {
    level: SponsorLevel.PLATINUM,
    site: 'https://www.ouropretoinvestimentos.com.br/',
    markets: ['FIDC'],
    image: 'ouro',
  },
  {
    level: SponsorLevel.PLATINUM,
    site: 'https://www.celcoin.com.br/',
    markets: ['FIDC'],
    image: 'celcoin',
  },
  {
    level: SponsorLevel.BRONZE,
    site: 'https://www.broadcast.com.br/',
    markets: ['FII', 'FIDC', 'FIAGRO'],
    image: 'broadcast',
  },
  {
    level: SponsorLevel.GOLD,
    site: 'https://cepeda.law/',
    markets: ['FIAGRO'],
    image: 'cepeda',
  },
];

export const marketData: MarketDataList = {
  CRs: [...crsPrimaryMarkets, ...crsSecondaryMarkets, ...crsStock],
  FII: [...fiiPrimaryMarkets, ...fiiSecondaryMarkets, ...fiiStockMarkets],
  FIDC: [...fidcPrimaryMarkets, ...fidcSecondaryMarkets, ...fidcStockMarkets],
  FIAGRO: [...fiagroPrimaryMarkets, ...fiagroSecondaryMarkets, ...fiagroStock],
};

export const marketRankings: MarketRankingList = {
  CRs: crsRankings,
  FII: fiiRankings,
  FIDC: fidcRankings,
  FIAGRO: fiagroRankings,
};

export const articlesData: MarketArticlesList = {
  CRs: crsArticles,
  FIDC: fidcArticles,
  FII: [],
  FIAGRO: [],
};

export const marketVideos: YearbookMarketVideo[] = [
  {
    title: 'Conteúdo Audiovisual - Ouro Preto',
    author: 'Ouro Preto',
    market: 'FIDC',
    videoId: '1068273809',
  },
  {
    title: 'Conteúdo Audiovisual - Celcoin',
    author: 'Celcoin',
    market: 'FIDC',
    videoId: '1068272640',
  },
  {
    title: 'Institucional - Ouro Preto',
    author: 'Ouro Preto',
    market: 'FIDC',
    videoId: '1070334530',
  },
  {
    title: 'Institucional - Celcoin',
    author: 'Celcoin',
    market: 'FIDC',
    videoId: '1070332417',
  },
  {
    title: 'Conteúdo Audiovisual - PMK',
    author: 'PMK',
    market: 'CRs',
    videoId: '1070324749',
  },
];

export const letterToReader: LetterToReader = {
  title: 'Carta ao leitor',
  content: [
    {
      paragraph:
        'É com grande satisfação que apresentamos a 18ª edição dos Anuários Uqbar, a publicação que documenta a evolução e os desafios do mercado de Finanças Estruturadas no Brasil. O ano de 2024 foi abundante em marcos históricos, consolidação de tendências e ajustes regulatórios que impactaram significativamente o setor.',
    },
    {
      paragraph:
        'O ano começou com mudanças expressivas no ambiente regulatório. A Resolução CMN 5.118 impôs restrições a alguns lastros de Certificados de Recebíveis Imobiliários (CRIs) e Certificados de Recebíveis do Agronegócio (CRAs), exigindo adaptações do mercado e afetando a estruturação de novas operações. No entanto, contrariando as expectativas iniciais, o mercado de CRIs manteve seu crescimento, enquanto o de CRAs registrou uma leve redução, não necessariamente atribuída à nova norma. Destaca-se também o desempenho robusto do mercado secundário desses títulos, que bateu novos recordes, sinalizando liquidez crescente e confiança renovada',
    },
    {
      paragraph:
        'Ainda no campo dos títulos de crédito, outra tendência relevante foi a expansão acelerada do mercado de Certificados de Recebíveis (CRs), muitos deles posteriormente tokenizados e distribuídos via crowdfunding. Atenta a esse movimento, a Uqbar passou a disponibilizar, por meio de sua plataforma, artigos e dados exclusivos sobre esse mercado emergente, que se diferencia dos CRIs e CRAs ao oferecer uma alternativa de financiamento para diversos setores econômicos. Esta edição do Anuário traz informações inéditas e dados detalhados sobre esse novo mercado.',
    },
    {
      paragraph:
        'No universo dos fundos de investimento, o grande marco foi a entrada em vigor, de modo irrestrito, da Resolução CVM 175, que reformulou o arcabouço regulatório de toda a indústria, redefinindo paradigmas e introduzindo novas dinâmicas ao setor. Em 2024, o volume de emissões e o patrimônio líquido atingiram níveis recordes, reafirmando o papel central dessa modalidade no financiamento empresarial e na diversificação de portfólios.',
    },
    {
      paragraph:
        'Destaque também para o mercado de Fundos de Investimento Imobiliário (FIIs), que teve um ano de forte recuperação, marcando o fim de um período de retração. As emissões no mercado primário cresceram 50% em relação ao ano anterior. O segmento "Imóvel" continuou responsável pela maior parte dessas emissões, enquanto o segmento "Renda Fixa" expandiu significativamente sua participação, refletindo um movimento de diversificação das estratégias dos investidores.',
    },
    {
      paragraph:
        'Apesar dos avanços, o mercado também enfrentou desafios significativos, especialmente no segmento de CRAs. Casos de inadimplência em operações específicas acenderam um alerta sobre os riscos associados a esses títulos. Esses eventos não apenas impactaram diretamente os investidores desses papéis, mas também repercutiram em outros segmentos, como os Fundos de Investimento nas Cadeias Produtivas Agroindustriais (Fiagros), cujas carteiras foram substancialmente afetadas. Esse foi um dos fatores que explicam a queda no volume de emissões desses fundos (Fiagro FIDC + Fiagro FII), apesar do aumento no número de fundos em operação.',
    },
    {
      paragraph:
        'Outro desafio que persiste é a necessidade de aprimoramento na disponibilidade e qualidade das informações do setor. Ao longo do ano, registramos em nossos artigos e eventos preocupações com a completude dos dados públicos e a dificuldade de acesso a informações no nascente mercado de CRs, um problema ainda mais relevante dado o ritmo acelerado de crescimento desse segmento. Também vale mencionar a falta de avanços em critérios ESG nos CRAs, contrariando expectativas e evidenciando os desafios para sua ampla adoção.',
    },
    {
      paragraph:
        'Desafios fazem parte do amadurecimento de qualquer mercado. As adversidades enfrentadas em 2024 não devem ofuscar os avanços expressivos alcançados ao longo do ano. São oportunidades para ajustes e melhorias, impulsionando inovação, regulação e maior sofisticação dos instrumentos financeiros. Os percalços no caminho fazem parte do processo de crescimento, e a história do mercado de Finanças Estruturadas no Brasil é prova de sua resiliência e capacidade de evolução constante.',
    },
    {
      paragraph:
        'Diante desse cenário dinâmico, os Anuários Uqbar seguem firmes em sua missão de registrar, analisar e contextualizar as transformações do mercado, oferecendo uma visão abrangente e imparcial dos desenvolvimentos mais relevantes do ano.',
    },
    {
      paragraph:
        'Agradecemos aos nossos patrocinadores, que seguem acreditando na importância da disseminação de conhecimento e da transparência para o fortalecimento do mercado de Finanças Estruturadas.',
    },
    {
      paragraph:
        'Desejamos a todos uma excelente leitura e permanecemos abertos a sugestões, críticas e elogios que contribuam para a constante evolução deste trabalho.',
    },
  ],
  author: {
    name: 'Carlos Augusto Lopes',
    role: 'Fundador e CEO',
    link: 'https://uqbar.com.br/profissional/carlos-augusto-lopes-ceo-uqbar/127',
    image:
      'https://static.uqbaronline.com/production-backend/c91929b7b0dab0671befb27561e24d30.jpg',
  },
  greeting: 'Saudações Uqbarianas',
};
