import { useEffect, useState } from 'react';

import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import MetaTags from 'src/components/Seo/MetaTags';
import { useAuthContext } from 'src/context/AuthContextProvider';

import { AdversimentBanner } from './components/Adversiment';
import NavigationMenu from './components/NavigationMenu';
import { SideMenu } from './components/SideMenu';
import { noPermissionPaywallData, paywallData } from './data/presentation';
import { ArticlesSection } from './sections/Articles';
import { Intro } from './sections/Intro';
import { LetterToReader } from './sections/LetterToReader';
import { SectionMarket } from './sections/Markets/SectionMarket';
import { Opening } from './sections/Opening';
import { Rankings } from './sections/Rankings';
import { Sponsors } from './sections/Sponsors';
import { VideoGallery } from './sections/VideoGallery';
import { MarketName, YearbookRouteParams } from './types';
import { getAdvertisementByPosition, getMarketByName } from './utils';
import IntroBg from '../../../assets/imgs/yearbook/2025/intro-bg-with-logo.png';

const sideMenuSx: SxProps<Theme> = {
  position: 'fixed',
  left: 0,
  top: 'calc(50% - 180px)',
  width: 130,
  maxHeight: '210px',
  padding: 1,
  backgroundColor: 'rgba(3, 3, 3, 0.4)',
  gap: 1,
  zIndex: 999,
};

const availablePLans = ['individual', 'team', 'vip'];
const publicMarkets = ['CRs', 'FII', 'FIDC', 'FIAGRO'];

export function Yearbooks2025({ paywallBlur }: { paywallBlur: boolean }) {
  const { auth } = useAuthContext();
  const [selectedMarket, setSelectedMarket] = useState<MarketName>(
    publicMarkets[0] as MarketName,
  );
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg'),
  );
  const {
    params: { market, section },
  } = useRouteMatch<YearbookRouteParams>();

  useEffect(() => {
    if (market && section) {
      history.push(`/anuarios/2025/${market}/${section}`);
      return;
    }
  }, []);

  useEffect(() => {
    if (market) {
      const marketName = getMarketByName(market);

      if (marketName) setSelectedMarket(marketName);
    }
  }, [market]);

  const paywallTexts =
    auth.user?.subscription?.plan?.type &&
    !availablePLans.includes(auth.user?.subscription?.plan?.type)
      ? noPermissionPaywallData
      : paywallData;

  const hasPermission =
    !paywallBlur ||
    availablePLans.includes(auth.user?.subscription?.plan?.type || '') ||
    publicMarkets.includes(selectedMarket);

  if (!hasPermission) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100dvh',
          backgroundColor: 'rgba(3, 3, 3, 0.800)',
        }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundImage: `url(${IntroBg})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            filter: !hasPermission ? 'blur(10px)' : 'none',
          }}
        />
        <Box
          sx={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            zIndex: 999,
            '& .MuiPaper-root': {
              maxWidth: '1000px',
              height: '320px',

              '& .MuiGrid-root': {
                height: '100%',
                justifyContent: 'center',

                '& .MuiGrid-container': {
                  height: '100%',
                },
              },
            },
          }}>
          <CardDemonstration
            title={paywallTexts.title}
            subTitle={paywallTexts.subtitle}
            labelButton={paywallTexts.buttonLabel}
            url={paywallTexts.url}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <MetaTags
        title="Anuários Uqbar 2025"
        description="Mais do que uma publicação, uma experiência completa nos mercados de CRA, CRI, FIDC e FII"
      />
      <NavigationMenu market={selectedMarket} section={section} />
      <SideMenu
        market={selectedMarket}
        sx={{ ...sideMenuSx, display: isMobile ? 'none' : 'block' }}
        titleComponent={
          <Typography sx={{ fontSize: 14, fontWeight: 600, color: 'white' }}>
            Você está lendo:
          </Typography>
        }
      />
      <Intro market={selectedMarket} />
      {hasPermission && (
        <>
          <Sponsors market={selectedMarket} />
          <LetterToReader />
          <Opening market={selectedMarket} />
          <AdversimentBanner
            advertisement={getAdvertisementByPosition(selectedMarket, 1)}
          />
          <SectionMarket
            market={selectedMarket}
            section="primary"
            title="Mercado Primário"
          />

          <SectionMarket
            market={selectedMarket}
            section="secondary"
            title="Mercado Secundário"
          />
          <AdversimentBanner
            advertisement={getAdvertisementByPosition(selectedMarket, 2)}
          />

          <SectionMarket
            market={selectedMarket}
            section="stock"
            title="Estoque"
          />

          <AdversimentBanner
            advertisement={getAdvertisementByPosition(selectedMarket, 3)}
          />
          <Rankings market={selectedMarket} />

          <AdversimentBanner
            advertisement={getAdvertisementByPosition(selectedMarket, 4)}
          />
          <ArticlesSection market={selectedMarket} />

          <VideoGallery market={selectedMarket} />
        </>
      )}
    </Box>
  );
}
